// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.path-navigation .breadcrumb {
  display: flex !important;
}

.path-navigation {
  display: flex;
  overflow: hidden;
}

.browserbar {
  display: flex;
  padding: 5px 10px !important;
}

.browserbar.overflow .path-navigation {
  justify-content: flex-end;
}

.browserbar .path-navigation .path-token .section {
  margin: 3px 0px !important;
}

.browserbar .path-token.current {
  padding: 3px 5px !important;
  font-weight: 900;
  font-size: 14px;
  color: black !important;
  margin: 0px !important;
}

.browserbar.overflow .path-token.current > div {
  left: auto !important;
  right: 0 !important;
  align-items: baseline;
}

.browserbar .path-token {
  display: flex;
  align-items: baseline;
}

.browserbar .section > div {
  width: 100% !important;
}

/* 
* Max width is meant to prevent the parent directory from going off-screen
* if the currently selected directory is really long (thus preventing navigation)
* Tested with download dialog (as it was the most narrow one)
*/

.browserbar .section-caption {
  max-width: 450px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  line-height: 1.2;
}

@media (max-width: 700px) {
  .browserbar .section-caption {
    max-width: 150px;
  }
}

@media (max-width: 1000px) {
  .browserbar .section-caption {
    max-width: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/browserbar/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;;;CAIC;;AAED;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".path-navigation .breadcrumb {\n  display: flex !important;\n}\n\n.path-navigation {\n  display: flex;\n  overflow: hidden;\n}\n\n.browserbar {\n  display: flex;\n  padding: 5px 10px !important;\n}\n\n.browserbar.overflow .path-navigation {\n  justify-content: flex-end;\n}\n\n.browserbar .path-navigation .path-token .section {\n  margin: 3px 0px !important;\n}\n\n.browserbar .path-token.current {\n  padding: 3px 5px !important;\n  font-weight: 900;\n  font-size: 14px;\n  color: black !important;\n  margin: 0px !important;\n}\n\n.browserbar.overflow .path-token.current > div {\n  left: auto !important;\n  right: 0 !important;\n  align-items: baseline;\n}\n\n.browserbar .path-token {\n  display: flex;\n  align-items: baseline;\n}\n\n.browserbar .section > div {\n  width: 100% !important;\n}\n\n/* \n* Max width is meant to prevent the parent directory from going off-screen\n* if the currently selected directory is really long (thus preventing navigation)\n* Tested with download dialog (as it was the most narrow one)\n*/\n\n.browserbar .section-caption {\n  max-width: 450px;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 100%;\n  line-height: 1.2;\n}\n\n@media (max-width: 700px) {\n  .browserbar .section-caption {\n    max-width: 150px;\n  }\n}\n\n@media (max-width: 1000px) {\n  .browserbar .section-caption {\n    max-width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
